import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../../components/IT/layout'

import pic11 from '../../assets/images/pic11.jpg'

const Address = (props) => (
    <Layout>
        <Helmet>
            <title>Unidans Project</title>
            <meta name="description" content="Generic Page" />
        </Helmet>

        <div id="main" className="alt">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h1> A CHI CI RIVOLGIAMO </h1>
                    </header>
                    <span className="image main"><img src={pic11} alt="" /></span>
                    
                    <h3> BENEFICIARI DIRETTI </h3>
                        <ul>
                            <li> Istruttori che lavorano nel campo dello sport integrato in
generale e Tecnici che lavorano nel settore della danza integrata
in particolare</li> 
                            <li>  Associazioni/istituzioni/palestre che lavorano nel campo
dell’inclusione sociale e nel settore dello sport e della danza che
sono interessati a formare i propri Istruttori nella disciplina della
danza integrata </li>
                            <li> Persone con e senza disabilità </li>
                        
                        </ul>

                        <h3> BENEFICIARI INDIRETTI </h3>
        <ul>
            <li>Famiglie o membri familiari che si occupano di persone con
disabilità, rappresentanti legali, specialisti e membri della
comunità;</li>
            <li> Rappresentanti di Istituti per le politiche pubbliche nei Paesi partner; </li>
            <li> Rappresentanti di istituzioni pubbliche e private. </li>
        </ul>

                </div>
            </section>
        </div>

    </Layout>
)

export default Address